import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const LoginRoute = (props, ...rest) => {
  const Component = props.component
  const loggedIn = props.loggedIn
  return (<Route {...rest} render={props => (
    loggedIn
      ? (<Component {...props}/>)
      : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
      )
  )}/>
  )
}

const mapStateToProps = (state) => {
  return { loggedIn: state.LoginApp.loggedIn }
}

const PrivateRoute = connect(mapStateToProps)(LoginRoute)

export default PrivateRoute
