import React from 'react'
import { connect } from 'react-redux'
import '../css/jar.css'
import { fetchAdminMessages } from '../actions'
import AdminTableView from './AdminTable'

class JarViewComp extends React.Component {
  componentDidMount () {
    this.props.dispatch(fetchAdminMessages(this.props.account.username))
  }

  render () {
    const { adminMessages } = this.props

    return (
      <div className='container-fluid' height={this.props.template.compHeight}>
        <h1>Messages from Other Jars</h1>
        <div className='row col-md-12'>
          <div className='row col-lg-12' style={{ overflowX: 'scroll' }}>
            <AdminTableView height={this.props.template.compHeight - 255} data={adminMessages.size ? adminMessages.toJS() : []}/>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    errorMessage: state.JarApp.get('errorMessage'),
    adminMessages: state.JarApp.get('adminMessages'),
    account: state.LoginApp.account,
    template: state.Template
  }
}

const JarView = connect(mapStateToProps)(JarViewComp)

export default JarView
