import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doLogin } from '../actions/login'
import { Redirect } from 'react-router-dom'
import { fetchUsertype } from '../actions'

class Login extends Component {
  password
  email

  constructor (props) {
    super(props)
    this.state = {
      showLogin: false, // don't show login form until we check for a jwt
      error: '.',
      codeSent: false,
      showAuthentication: false,
      token: null,
      account: null
    }
    this.password = React.createRef()
    this.email = React.createRef()
  }

  componentDidMount () {
    const jwt = sessionStorage.getItem('jwt')
    const account = JSON.parse(sessionStorage.getItem('account'))
    const lastLogin = sessionStorage.getItem('vrjDate')
    const self = this

    if (jwt && new Date().toDateString() === lastLogin) {
      this.verify('/kudos-api/auth', jwt, function (res) {
        if (res.success) {
          self.props.dispatch(doLogin(account))
        } else {
          self.setState({ showLogin: true })
        }
      })
    } else {
      this.setState({ showLogin: true })
    }
  }

  fetch (path, body = '', callback) {
    const self = this

    // Build formData object.
    const formData = new FormData()
    formData.append('username', body.username)
    formData.append('password', body.password)
    formData.append('group', body.group)

    fetch(path, {
      accept: 'application/json',
      method: 'POST',
      body: new URLSearchParams(formData)
    }).then(this.checkStatus)
      .then(this.parseJSON)
      .then(callback)
      .catch(error => {
        /* eslint handle-callback-err: "warn" */
        console.log(error)
        self.setState({ error: 'Invalid username or password.' })
      })
  }

  verify (path, token = '', callback) {
    fetch(path, {
      accept: 'application/json',
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    }).then(this.checkStatus)
      .then(this.parseJSON)
      .then(callback)
      // eslint-disable-next-line no-console
      .catch(error => console.log(error))
  }

  checkStatus (response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    }
    const error = new Error(`HTTP Error ${response.statusText}`)
    error.status = response.statusText
    error.response = response
    throw error
  }

  parseJSON (response) {
    return response.json()
  }

  handleLogin (e) {
    e.preventDefault()
    const self = this

    if (!this.state.email || !this.state.password) {
      this.setState({ error: 'Invalid username or password.' })
    } else {
      this.setState({ error: '.' })

      const data = {
        username: this.state.email,
        password: this.state.password,
        group: ['rewardJar']
      }

      this.fetch('/account', data, function (res) {
        if (res.account) {
          self.setState({ showAuthentication: false })
          self.setState({ codeSent: false })
          self.setState({ token: res.token })
          self.setState({ account: res.account })

          const token = self.state.token
          const account = self.state.account
          sessionStorage.setItem('jwt', token)
          sessionStorage.setItem('vrjDate', new Date().toDateString())
          sessionStorage.setItem('username', account.username)
          sessionStorage.setItem('account', JSON.stringify(account))
          self.setState({ token: null })
          self.setState({ account: null })
          self.props.dispatch(doLogin(account, token))
          self.props.dispatch(fetchUsertype(account.username))
        } else {
          self.setState({ error: 'Invalid username or password.' })
        }
      })
    }
  }

  onFormChange (e) {
    const state = {}
    state[e.target.id] = e.target.value
    this.setState(state)
  }

  render () {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    if (this.props.loggedIn) {
      return (<Redirect to={from}/>)
    } else {
      return (
        <div className='container col-lg-12 d-flex'>
          <div className='col-lg-3'/>
          <div className='col-lg-6 align-self-center mt-5' style={{ display: this.state.showLogin ? 'block' : 'none' }}>
            <form onSubmit={this.handleLogin.bind(this)} className='form-horizontal'>
              <h3>Login</h3>
              <div className='error alert alert-warning' style={{ visibility: this.state.error.length > 1 ? 'visible' : 'hidden' }}>{this.state.error}</div>

              <div className='form-group row'>
                <label htmlFor='email' className='col-sm-2 control-label'>Username:</label>
                <div className='col-sm-10'>
                  <input ref={this.email} onChange={this.onFormChange.bind(this)} id='email' type='text' className='form-control' />
                </div>
              </div>

              <div className='form-group row mt-3 mb-3'>
                <label htmlFor='password' className='col-sm-2 control-label'>Password:</label>
                <div className='col-sm-10'>
                  <input ref={this.password} onChange={this.onFormChange.bind(this)} id='password' type='password' className='form-control'/>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-sm-9'/>
                <div className='col-sm-3'>
                  <button onClick={this.handleLogin.bind(this)} className='form-control btn btn-info'>Login</button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-lg-3'/>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return { loggedIn: state.LoginApp.loggedIn }
}

export default connect(mapStateToProps)(Login)
