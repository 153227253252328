import React from 'react'
import { connect } from 'react-redux'
import { fetchPeople, postMyMessages } from '../actions'
import Select from 'react-select'

class KudosViewComp extends React.Component {
  note
  userTo

  constructor (props) {
    super(props)
    this.selected = React.createRef()
    this.note = React.createRef()
    this.userTo = React.createRef()
    this.state = {
      userTo: { value: 0, label: 'Please choose a recipient' }
    }
  }

  componentDidMount () {
    if (!this.props.people.size) {
      this.props.dispatch(fetchPeople())
    }
  }

  onFormChange (e) {
    const state = {}
    state[e.target.id] = e.target.value
    this.setState(state)
  }

  onSelectChange (e) {
    this.setState({ userTo: e })
  }

  handleSubmit (e) {
    if (this.state.userTo.value === 0) {
      alert('You must choose a recipient to send a message')
    } else if (this.state.note === undefined) {
      alert('Please type a message')
    } else {
      e.preventDefault()
      const data = {
        note: encodeURIComponent(this.state.note),
        userTo: encodeURIComponent(this.state.userTo.value),
        userToUsername: encodeURIComponent(this.props.people.filter(p => p.get('id') === parseInt(this.state.userTo.value)).get(0).get('username')),
        usersName: encodeURIComponent(this.props.people.filter(p => p.get('id') === parseInt(this.state.userTo.value)).get(0).get('firstname')),
        usersLastName: encodeURIComponent(this.props.people.filter(p => p.get('id') === parseInt(this.state.userTo.value)).get(0).get('lastname')),
        nummessages: encodeURIComponent(this.props.people.filter(p => p.get('id') === parseInt(this.state.userTo.value)).get(0).get('nummessages')),
        fromFirstname: encodeURIComponent(this.props.people.filter(p => p.get('username') === this.props.currentUser.username).get(0).get('firstname')),
        fromLastName: encodeURIComponent(this.props.people.filter(p => p.get('username') === this.props.currentUser.username).get(0).get('lastname'))
      }
      this.props.dispatch(postMyMessages(this.props.currentUser.username, data))
      // reset values (only if no error)
      if (!this.props.errorMessage) {
        this.selected.current.select.clearValue()
        this.note.current.value = ''
      }
    }
  }

  render () {
    const { people } = this.props
    const peopleSelect = people.filter(p => p.get('username') !== this.props.currentUser.username && p.get('usertype') !== 3).map((person, i) => {
      return (
        { label: person.get('firstname') + ' ' + person.get('lastname'), value: person.get('id') }
      )
    })

    return (
      <div className='col-lg-12 row' height={this.props.template.compHeight}>
        <h1>Send Kudos!</h1>
        <div className="row d-flex align-items-center">
          <div className="col-lg-1"/>
          <div className="col-lg-4">
            <form>
              <div className="col-lg-12">
                <div className="col-lg-12 form-group mb-4">
                  <label htmlFor="userTo" className="form-label">Send to:  </label>
                  <div style={{ padding: '5px', zIndex: 10000 }}>
                    <Select
                      ref={ this.selected }
                      value={ peopleSelect[0] }
                      options={ people.size ? peopleSelect : null }
                      onChange={this.onSelectChange.bind(this)}
                      clearable={false}
                      placeholder={'Please choose a recipient'}
                      style={{ textAlign: 'left' }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 form-group">
                  <p>Write a note:</p>
                  <textarea className="form-control" id="note" ref={this.note} onChange={this.onFormChange.bind(this)} name="noteText" rows="8" cols="60"></textarea>
                </div>
                <div className="col-lg-12 mt-4">
                  <button onClick={this.handleSubmit.bind(this)} className="btn btn-info btn-block float-end" type="submit" name="button">Send Kudos!</button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-2"/>
          <div id="drop-region" className="col-lg-4">
            <img src="images/masonjar.png" alt="candy jar"></img>
          </div>
          <div className="col-lg-1"/>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    people: state.KudosApp.get('people'),
    errorMessage: state.KudosApp.get('errorMessage'),
    template: state.Template,
    currentUser: state.LoginApp.account
  }
}

const KudosView = connect(mapStateToProps)(KudosViewComp)

export default KudosView
