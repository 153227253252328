import React, { Component } from 'react'
import { connect } from 'react-redux'
import Navigation from './Navigation'
import ErrorBoundary from './ErrorBoundary'

class App extends Component {
  render () {
    return (
      <div>
        <ErrorBoundary>
          <Navigation />
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.ConfigApp
  }
}

export default connect(mapStateToProps)(App)
