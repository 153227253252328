import { List, Map, fromJS } from 'immutable'

const initialState = Map({
  errorMessage: null,
  myMessages: List(),
  adminMessages: List()
})

const JarApp = (state = initialState, action) => {
  switch (action.type) {
  case 'ERROR':
    return state.set('errorMessage', action.message)
  case 'CLEAR_ERROR_MESSAGE':
    return state.set('errorMessage', null)
  case 'RECEIVE_MY_MESSAGES':
    return state.set('myMessages', fromJS(action.data))
  case 'RECEIVE_ADMIN_MESSAGES':
    return state.set('adminMessages', fromJS(action.data))
  default:
    return state
  }
}

export default JarApp
