const initialState = {
  loggedIn: false, // process.env.NODE_ENV === 'development',
  account: {},
  token: '',
  usertype: 0
}

const LoginApp = (state = initialState, action) => {
  switch (action.type) {
  case 'LOGIN':
    // if (process.env.NODE_ENV === 'development') action.account.username = 'kblack' // test duffy login (or others)
    /* eslint-disable-next-line no-case-declarations */
    const newState = Object.assign({}, state)
    newState.loggedIn = true
    newState.account = action.account
    newState.token = action.token
    return newState

  case 'LOGOUT':
    localStorage.removeItem('username')
    localStorage.removeItem('email')
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
    localStorage.removeItem('account')
    localStorage.removeItem('time')
    localStorage.clear()
    sessionStorage.removeItem('jwt')
    sessionStorage.clear()
    return initialState

  case 'RECEIVE_USER_TYPE':
    /* eslint-disable-next-line no-case-declarations */
    const newerState = Object.assign({}, state)
    newerState.usertype = action.data[0].usertype
    return newerState

  default:
    return state
  }
}

export default LoginApp
