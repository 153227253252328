import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { screenResize } from './actions'
import './css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

document.title = 'Virtual Reward Jar'

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

// console.log(process.env.NODE_ENV)

window.addEventListener('resize', () => {
  store.dispatch(screenResize(window.innerWidth))
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
