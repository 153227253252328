import { Map, List, fromJS } from 'immutable'

const initialState = Map({
  people: List(),
  errorMessage: null
})

const KudosApp = (state = initialState, action) => {
  switch (action.type) {
  case 'ERROR':
    return state.set('errorMessage', action.message)
  case 'CLEAR_ERROR_MESSAGE':
    return state.set('errorMessage', null)
  case 'RECEIVE_PEOPLE':
    return state.set('people', fromJS(action.data))
  default:
    return state
  }
}

export default KudosApp
