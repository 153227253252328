import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import masonJar from '../css/images/masonjar.png'
import jellyBean from '../css/images/jellybean.png'
import '../css/jar.css'
import { fetchMyMessages, fetchPeople, updateCongratsField } from '../actions' // clearErrorMessage

class JarViewComp extends React.Component {
  // random position functions
  myRandom = function (min, max) {
    const diff = max - min
    const rand = (Math.random() * diff) + min
    return rand
  }

  myRandomColor = function () {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return '#' + randomColor
  }

  listToMatrix = function (list, elementsPerSubArray) {
    const matrix = []
    for (let i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
        k++
        matrix[k] = []
      }
      matrix[k].push(list[i])
    }
    return matrix
  }

  gotoCongrats = function () {
    window.location.href = '/congrats'
    // update congrats variable in database
  }

  componentDidMount () {
    if (!this.props.people.size) {
      this.props.dispatch(fetchPeople())
    }
    this.props.dispatch(fetchMyMessages(this.props.account.username))
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const person = this.props.people.size ? this.props.people.filter(p => p.get('username') === this.props.account.username) : null
    if (person && person.size) {
      const messages = this.props.people.filter(p => p.get('username') === this.props.account.username).get(0).get('nummessages')
      if (Math.floor(messages / 10) > person.get(0).get('congrats')) {
        this.props.dispatch(updateCongratsField(this.props.account.username))
        window.location.href = '/congrats'
      }
    }
  }

  render () {
    const { myMessages, people } = this.props
    const tableRows = myMessages.map((m, i) => {
      const person = people.filter(p => p.get('id') === m.get('sent_from_id'))
      return (
        <tr key={i}>
          <th width="5%" scope="row">{i + 1}</th>
          <td width="15%">{moment(m.get('date_sent')).format('MMMM D, YYYY')}</td>
          <td width="25%">{person.size ? person.get(0).get('firstname') + ' ' + person.get(0).get('lastname') : ''}</td>
          <td className="message">{m.get('message')}</td>
        </tr>
      )
    })

    function range (start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
    const vector = range(1, 10000)

    const numMessages = myMessages.size
    const messagesArray = vector.slice(0, numMessages)

    const myMessagesAsMatrix = messagesArray.map((m, i) => {
      return (
        this.listToMatrix(messagesArray, 2)
      )
    })

    let index = 0
    const myCandyBottom = !myMessages.size
      ? null
      : myMessagesAsMatrix[0].map((m, i) => {
        return (
          myMessagesAsMatrix[0][i].map((m, j) => {
            index++
            return (
              <div key={index}>
                <img key={index} src={jellyBean} alt="Jelly Bean" className="jellyBean" style= {{ right: this.myRandom(-100, 100) - this.props.template.width / 2 - 50, top: this.myRandom(-580, -900), filter: 'hue-rotate(' + this.myRandom(0, 360) + 'deg)' }}/>
              </div>
            )
          })
        )
      })

    const dummy = [0]
    const messagesTable = dummy.map((m, i) => {
      if (myMessages.size > 0) {
        return (
          <table className='table table-striped table-dark' style={{ marginLeft: 0, marginRight: 0, display: 'table' }} key='missing'>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date Received</th>
                <th scope="col">From</th>
                <th scope="col">Message</th>
              </tr>
            </thead>
            <tbody>
              { myMessages.size ? tableRows : null }
            </tbody>
          </table>
        )
      } else {
        return (
          <p className='col-sm-12' key={i}> Messages will appear here </p>
        )
      }
    })

    return (
      <div className='container-fluid' height={this.props.template.compHeight}>
        <div className='col-sm-12'>
          <div className='w-50 mx-auto text-center' style={{ minHeight: '50px' }}></div>
        </div>
        <div className='col-sm-12'>
          <div className='mx-auto text-center col'>
            <img src={masonJar} alt="Mason Jar" />
          </div>
          <div className='mx-auto text-center col position-absolute'>
            { myMessages.size ? myCandyBottom : null }
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='w-50 mx-auto text-center' style={{ minHeight: '50px' }}></div>
        </div>
        <div className='col-md-12'>
          <div className='col-md-8 offset-md-2 mx-auto'>
            { messagesTable }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    errorMessage: state.JarApp.get('errorMessage'),
    myMessages: state.JarApp.get('myMessages'),
    people: state.KudosApp.get('people'),
    account: state.LoginApp.account,
    template: state.Template
  }
}

const JarView = connect(mapStateToProps)(JarViewComp)

export default JarView
