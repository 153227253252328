import React from 'react'
import { connect } from 'react-redux'
import '../css/kudos.css'
// import { clearErrorMessage } from '../actions'

class AccountViewComp extends React.Component {
  render () {
    return (
      <div className='kudosApp' height={this.props.template.compHeight}>
        My Account!
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    errorMessage: state.JarApp.get('errorMessage'),
    template: state.Template
  }
}

const AccountView = connect(mapStateToProps)(AccountViewComp)

export default AccountView
