import React from 'react'
import { useTable, useSortBy, useFilters, useFlexLayout, useResizeColumns } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../css/adminTable.css'
import moment from 'moment'

const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'right') => [
  props,
  {
    style: {
      justifyContent: align === 'center' ? 'center' : (align === 'right' ? 'flex-end' : 'flex-start'),
      alignItems: 'flex-start',
      display: 'flex',
    }
  }
]

// Define a default UI for filtering
function DefaultColumnFilter ({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (<div/>)
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <div className="form-group">
      <select className="form-control" multiple="" value={filterValue} onChange={e => { setFilter(e.target.value || undefined) }}>
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
// eslint-disable-next-line no-unused-vars
function SliderColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length && preFilteredRows[0].values[id] !== null ? parseFloat(preFilteredRows[0].values[id], 10) : 0
    let max = preFilteredRows.length && preFilteredRows[0].values[id] !== null ? parseFloat(preFilteredRows[0].values[id], 10) : 0
    preFilteredRows.forEach(row => {
      if (row.values[id] !== null) {
        min = Math.min(parseFloat(row.values[id], 10), min)
        max = Math.max(parseFloat(row.values[id], 10), max)
      }
    })
    return [min, max]
  }, [id, preFilteredRows])
  return (
    <div className="form-group">
      <input
        className="custom-range"
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseFloat(e.target.value, 10))
        }}
      />
      <button type="button" className="btn btn-outline-danger" onClick={() => setFilter(undefined)}>Off</button>
    </div>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
// eslint-disable-next-line no-unused-vars
function NumberRangeColumnFilter ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? parseFloat(preFilteredRows[0].values[id], 10) : 0
    let max = preFilteredRows.length ? parseFloat(preFilteredRows[0].values[id], 10) : 0
    preFilteredRows.forEach(row => {
      min = Math.min(parseFloat(row.values[id], 10), min)
      max = Math.max(parseFloat(row.values[id], 10), max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div className='form-group-sm'
      style={{
        display: 'flex',
      }}
    >
      <input
        className="form-control input-sm"
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '50px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        className="form-control input-sm"
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '50px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

// Create a default prop getter
const defaultPropGetter = () => ({})

// Our table component
function Table ({
  columns,
  data,
  maxHeight,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  hiddenColumns = []
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      lesser: filterLesserThan,
      greater: filterGreaterThan,
      dateBetween: dateBetweenFilterFn, /* <- LIKE THIS */
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 400,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      maxHeight,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: []
      }
    },
    useFilters, // useFilters!
    useSortBy,
    useFlexLayout,
    useResizeColumns
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows // .slice(0, 1000)

  return (
    <>
      <div {...getTableProps()} className='table table-striped'>
        <div className='thead'>
          {headerGroups.map((headerGroup, hi) => (
            <div key={hi} {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column, ci) => (
                <div key={ci} {...column.getHeaderProps(column.getSortByToggleProps())} className='th'>
                  <div style={{ cursor: 'pointer' }}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    { column.isSorted
                      ? column.isSortedDesc
                        ? (<FontAwesomeIcon className="icon-right" icon={faChevronDown} />)
                        : (<FontAwesomeIcon className="icon-right" icon={faChevronUp} />)
                      : null }
                  </div>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  )}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                  />
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className='tbody' style={{ maxHeight: maxHeight }}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <div key={i} {...row.getRowProps()} className='tr'>
                {row.cells.map((cell, c) => {
                  return <div key={c} {...cell.getCellProps(cellProps)} className='td'>{cell.render('Cell')}</div>
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan (rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = parseFloat(row.values[id], 10)
    return rowValue >= filterValue
  })
}

// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof parseFloat(val, 10) !== 'number'

function DateRangeColumnFilter ({
  column: {
    filterValue = [],
    preFilteredRows,
    setFilter,
    id
  }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
    let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)

    preFilteredRows.forEach(row => {
      const rowDate = new Date(row.values[id])

      // these are the range of dates in the table
      min = rowDate <= min ? rowDate : min
      max = rowDate >= max ? rowDate : max
    })

    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div>
      <input
        min={min.toISOString().slice(0, 10)}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val || undefined, old[1]])
        }}
        type="date"
        value={filterValue[0] || ''}
      />
      {' to '}
      <input
        max={max.toISOString().slice(0, 10)}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? val.concat('T23:59:59.999Z') : undefined])
        }}
        type="date"
        value={filterValue[1]?.slice(0, 10) || ''}
      />
    </div>
  )
}

function dateBetweenFilterFn (rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined

  if (ed || sd) {
    return rows.filter(r => {
      const time = new Date(r.values[id])

      if (ed && sd) {
        return (time >= sd && time <= ed)
      } else if (sd) {
        return (time >= sd)
      } else {
        return (time <= ed)
      }
    })
  } else {
    return rows
  }
}

dateBetweenFilterFn.autoRemove = val => !val

// Define a custom filter filter function!
function filterLesserThan (rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = parseFloat(row.values[id], 10)
    return rowValue <= filterValue
  })
}

// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterLesserThan.autoRemove = val => typeof parseFloat(val, 10) !== 'number'

const AdminTable = (props) => {
  function MyDateCell ({ value }) {
    return <p>{moment(value).format('MM/DD/YYYY')}</p>
  }

  function MyMessageCell ({ value }) {
    // return <p>{decodeURI(value)}</p>
    return <p>{value}</p>
  }

  const columns = [
    {
      Header: 'Date Received',
      accessor: 'date_sent',
      Cell: MyDateCell,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      canFilter: true,
      width: 100,
      show: true
    },
    {
      Header: 'From',
      accessor: d => `${d.firstfrom} ${d.lastfrom}`,
      Filter: SelectColumnFilter,
      canFilter: true,
      width: 100,
      show: true
    },
    {
      Header: 'To',
      accessor: d => `${d.firstto} ${d.lastto}`,
      Filter: SelectColumnFilter,
      canFilter: true,
      width: 75,
      show: true
    },
    {
      Header: 'Message',
      accessor: 'message',
      Cell: MyMessageCell,
      canFilter: false,
      width: 150,
      show: true
    }
  ]

  return (
    <div style={{ paddingBottom: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
      <Table
        columns={columns}
        data={props.data}
        maxHeight={props.height}
      />
    </div>
  )
}

export default AdminTable
