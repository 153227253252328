import React from 'react'
import { connect } from 'react-redux'
import '../css/congrats.css'
import ReactCanvasConfetti from 'react-canvas-confetti'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}

class CongratulationsViewComp extends React.Component {
  constructor (props) {
    super(props)
    this.isAnimationEnabled = false
    this.animationInstance = null
    this.nextTickAnimation = this.nextTickAnimation.bind(this)
  }

  makeShot = (angle, originX) => {
    this.animationInstance && this.animationInstance({
      particleCount: 3,
      angle,
      spread: 55,
      origin: { x: originX }
    })
  }

  nextTickAnimation = () => {
    this.makeShot(60, 0)
    this.makeShot(120, 1)
    if (this.isAnimationEnabled) requestAnimationFrame(this.nextTickAnimation)
  }

    startAnimation = () => {
      if (!this.isAnimationEnabled) {
        this.isAnimationEnabled = true
        this.nextTickAnimation()
      }
    }

    stopAnimation = () => {
      this.isAnimationEnabled = false
      this.animationInstance && this.animationInstance.reset()
    }

    handlerLoadStart = () => {
      this.startAnimation()
    };

    handlerClickStop = () => {
      this.stopAnimation()
    };

    getInstance = (instance) => {
      this.animationInstance = instance
    };

    componentDidMount () {
      this.handlerLoadStart()
      window.addEventListener('click', this.handlerClickStop)
      window.setTimeout(function () { // never redirects back to jar
        window.location.href = '/jar'
      }, 10000)
    }

    render () {
      return (
        <div>
          <>
            <ReactCanvasConfetti
            // set the styles as for a usual react component
              style={canvasStyles}
              // set the class name as for a usual react component
              className={'yourClassName'}
              // if value in this.state.fire cast to the logical true and will differ from the previous, then will be called new animation
              refConfetti={this.getInstance}
            // if value in this.state.reset cast to the logical true and will differ from the previous, then will be cleared canvas
            />
          </>
          <div>
            <h1>Congratulations!</h1>
            <div className="row">
              <h3> You have 10 kudos! </h3>
            </div>
            <div className="row">
              <p>(Click to stop confetti)</p>
            </div>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    people: state.KudosApp.get('people'),
    errorMessage: state.KudosApp.get('errorMessage'),
    template: state.Template
  }
}

const CongratulationsView = connect(mapStateToProps)(CongratulationsViewComp)

export default CongratulationsView
