import { combineReducers } from 'redux'
// import DataApp from './data'
import JarApp from './jar'
import KudosApp from './kudos'
import LoginApp from './login'
import appStyles from '../css/index.css'

// TODO - why isn't this working?!
// console.log(appStyles)

function isFetching (state = false, action) {
  switch (action.type) {
  case 'FETCHING':
    return true

  case 'DONE':
  case 'ERROR':
    return false

  default:
    return state
  }
}

function isBusy (state = false, action) {
  switch (action.type) {
  case 'BUSY':
    return true

  case 'DONE':
  case 'ERROR':
    return false

  default:
    return state
  }
}

function busyText (state = 'Loading...', action) {
  switch (action.type) {
  case 'FETCHING':
  case 'BUSY':
    return action.text
  default:
    return state
  }
}

const templateInitialState = {
  height: window.innerHeight,
  width: window.innerWidth,
  navHeight: appStyles.navHeight || 42,
  compHeight: window.innerHeight - (appStyles.navHeight || 42)
}

function UserState (state = { user_id: 0 }, action) {
  switch (action.type) {
  default:
    return state
  }
}

function Template (state = templateInitialState, action) {
  switch (action.type) {
  case 'SCREEN_RESIZE':
    /* eslint-disable-next-line no-case-declarations */
    const newState = Object.assign({}, state)
    newState.height = window.innerHeight
    newState.width = window.innerWidth
    newState.compHeight = window.innerHeight - state.navHeight
    return newState

  default:
    return state
  }
}

const stateApp = combineReducers({
  isFetching, isBusy, busyText, JarApp, KudosApp, Template, UserState, LoginApp // DataApp
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return stateApp(state, action)
}

export default rootReducer
