import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, NavLink, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Login from './views/Login'
import Account from './views/Account'
import JarView from './views/MyJar'
import AdminView from './views/Admin'
import KudosView from './views/SendKudos'
import CongratulationsView from './views/Congratulations'
import { doLogout } from './actions/login'

class Navigation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showNav: false,
      version: ''
      // baseroute: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '/' : '/' + process.env.REACT_APP_SITE
    }
  }

  clickLogout () {
    this.props.dispatch(doLogout())
  }

  toggleNav (show) {
    this.setState({ showNav: show })
  }

  render () {
    const navClass = this.state.showNav ? 'show' : ''
    // const { baseroute } = this.state

    return (
      <div>
        {/* <Router basename={baseroute}> */}
        <Router>
          <div>
            { this.props.show
              ? (<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className={ 'collapse navbar-collapse' + navClass } id="navbarColor01">
                  <ul className="navbar-nav me-auto" onClick={ this.toggleNav.bind(this, false) }>
                    <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/jar">My Jar</NavLink></li>
                    <li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/kudos">Send Kudos</NavLink></li>
                    { this.props.showOther > 0
                      ? (<li className="nav-item"><NavLink className='nav-link' activeClassName='nav_active' to="/admin">Other Jars</NavLink></li>)
                      : null }
                  </ul>
                  <ul className="navbar-nav ms-auto" onClick={ this.toggleNav.bind(this, false) }>
                    { /* <li className="nav-item float-end"><NavLink className='nav-link' activeClassName='nav_active' to="/account">Account</NavLink></li> */ }
                    <li className="nav-item float-end"><NavLink className='nav-link' onClick={this.clickLogout.bind(this)} to="/login">Logout</NavLink></li>
                  </ul>
                </div>
              </nav>)
              : null }
            <div>
              <Switch>
                <PrivateRoute path="/account" component={ Account }/>
                <PrivateRoute path="/jar" component={ JarView } />
                <PrivateRoute path="/kudos" component={ KudosView } />
                <PrivateRoute path="/admin" component={ AdminView } />
                <PrivateRoute path="/congrats" component={ CongratulationsView } />
                <Route path="/login" component={ Login } />
                <Redirect from="/" to="/jar"/>
              </Switch>
            </div>
          </div>
        </Router>
        <div id='busyIndicator' className={ this.props.isBusy ? 'isBusy' : 'isNotBusy' } >
          { this.props.busyText }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isBusy: state.isBusy,
    busyText: state.busyText,
    show: state.LoginApp.loggedIn,
    showOther: state.LoginApp.usertype
  }
}

export default connect(mapStateToProps)(Navigation)
