import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true })
    console.log(error)
  }

  render () {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '15px' }}>
          <h1>Oops. Something went wrong.</h1>
          <p>Please email aspringsteen@neptuneinc.org  with information about what caused this message if you can. <br></br>Thanks.</p>
          <p>You should refresh this page.</p>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
}

export default ErrorBoundary
