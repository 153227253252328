import Fetch from '../Fetch'
import { receiveMyMessages, receiveAdminMessages } from './jar'
import { receivePeople } from './kudos'

const receiveUsertype = (data) => ({
  type: 'RECEIVE_USER_TYPE',
  data
})

export const screenResize = () => ({
  type: 'SCREEN_RESIZE'
})

export const busy = (text) => dispatch => Promise.resolve(dispatch({ type: 'BUSY', text }))

export const fetching = (text) => dispatch => Promise.resolve(dispatch({ type: 'FETCHING', text }))

export const done = () => ({
  type: 'DONE'
})

export const showLoading = () => ({
  type: 'SHOW_LOADING'
})

export const hideLoading = () => ({
  type: 'HIDE_LOADING'
})

export const clearErrorMessage = () => ({
  type: 'CLEAR_ERROR_MESSAGE'
})

export const updateCongratsField = (username) => async dispatch => {
  return Fetch.update('/kudos-api/congrats/' + username).then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not update your congrats field in the database.' })
    } else {
      if (data.success) {
        dispatch(done())
      } else {
        // eslint-disable-next-line no-console
        console.log(data.error)
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}

export const fetchMyMessages = (username) => async dispatch => {
  await dispatch(busy('Getting your messages...'))
  return Fetch.search('/kudos-api/kudos/' + username).then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not retrieve your messages from the database.' })
    } else {
      if (data.success) {
        dispatch(receiveMyMessages(data.data))
        dispatch(done())
      } else {
        // eslint-disable-next-line no-console
        console.log(data.error)
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}

export const fetchAdminMessages = (username) => async dispatch => {
  await dispatch(busy('Getting messages...'))
  // return all messages... then return conditional on PAL (still need username)
  return Fetch.search('/kudos-api/admin/' + username).then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not retrieve messages from the database.' })
    } else {
      if (data.success) {
        dispatch(receiveAdminMessages(data.data))
        dispatch(done())
      } else {
        // eslint-disable-next-line no-console
        console.log(data.error)
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}

export const fetchUsertype = (username) => async dispatch => {
  await dispatch(busy('Getting messages...'))
  // return all messages... then return conditional on PAL (still need username)
  return Fetch.search('/kudos-api/usertype/' + username).then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not retrieve messages from the database.' })
    } else {
      if (data.success) {
        dispatch(receiveUsertype(data.data))
        dispatch(done())
      } else {
        // eslint-disable-next-line no-console
        console.log(data.error)
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}

export const postMyMessages = (username, text) => async dispatch => {
  await dispatch(busy('Sending your message...'))
  return Fetch.post('/kudos-api/kudos/' + username, text).then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not send your message to the database.' })
      console.log('message not sent to database (1)')
    } else {
      if (data.success) {
        dispatch(done())
        console.log('message sent to database (2)')
        // confirmation message
        if (data.errorMessage) {
          alert('Thanks, your message has been sent. However, email was not delivered.') // doesn't work need to fix
          // test with maildev off
        } else {
          alert('Thanks! Your message has been sent to your coworker')
        }
      } else {
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
        // eslint-disable-next-line no-console
        console.log('Message not sent to database (3)')
        console.log(data.errorMessage)
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}

export const fetchPeople = () => async dispatch => {
  await dispatch(busy('...'))
  return Fetch.search('/kudos-api/people').then(data => {
    if (data instanceof Error) {
      dispatch({ type: 'ERROR', message: 'Could not retrieve people from the database.' })
    } else {
      if (data.success) {
        dispatch(receivePeople(data.data))
        dispatch(done())
      } else {
        // eslint-disable-next-line no-console
        console.log(data.error)
        dispatch({ type: 'ERROR', message: 'Error: ' + data.error })
      }
    }
  }).catch(error => {
    console.log(error)
    dispatch({ type: 'ERROR', message: 'Error processing data.' })
  })
}
