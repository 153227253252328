import { store } from './index'

/* eslint-disable no-undef */
function checkStatus (response) {
  // if (response.status >= 200 && response.status < 300) {
  if (response.ok) {
    return response
  } else {
    /* if (response.status !== 404) {
      throw err(url, response.status, response.statusText)
    } */
    const error = new Error(response.statusText)
    error.response = response
    if (response.status === 401) {
      store.dispatch(doLogout())
    }
    throw error
  }
}

/* eslint-disable no-undef */
function search (query) {
  // console.log(query)
  const jwt = sessionStorage.getItem('jwt')
  return fetch(query, {
    accept: 'application/json',
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error)
}

function post (query, jsonBody) {
  const jwt = sessionStorage.getItem('jwt')
  return fetch(query, {
    method: 'POST',
    accept: 'application/json',
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(jsonBody)
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error)
}

function update (query, jsonBody) {
  const jwt = sessionStorage.getItem('jwt')
  return fetch(query, {
    method: 'PUT',
    accept: 'application/json',
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(jsonBody)
  }).then(checkStatus)
    .then((response) => response.json())
    .catch((error) => error)
}

/* eslint-disable no-undef */
function gbf (query) {
  const jwt = sessionStorage.getItem('jwt')
  return fetch(query, {
    accept: 'application/json',
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json'
    }
  }).then(checkStatus)
    .then((response) => response.arrayBuffer())
    .catch((error) => error)
}

const Fetch = { search, post, update, gbf }
export default Fetch
